.footer {
  background-color: $black;
  color: $white;

  z-index: 1;

  &__intro {
    margin-top: 75px;
    margin-bottom: 100px;
    font-size: 30px;
    line-height: 120%;

    @include for-tablet-landscape-up {
      max-width: 60%;
      font-size: 40px;
      margin-bottom: 150px;
    }

    strong {
      color: $brand;
    }
  }

  .contact__small {
    margin-bottom: 20px;
    color: $brand;
  }

  .contact__info {
    display: flex;
    flex-direction: column;

    margin-bottom: 50px;

    line-height: 150%;

    &__link {
      text-decoration: none;
      color: $white;

      transition: .3s ease-in-out;

      &:hover, &:focus {
        color: $brand;
      }
    }
  }

  &__button {
    @include for-tablet-landscape-up {
      justify-self: end;
    }
  }

   &__nav {
     display: flex;
     flex-direction: column;
     justify-content: space-between;

     margin-top: 150px;
     padding-top: 50px;

     border-top: 2px solid $white;

     @include for-tablet-landscape-up {
       flex-direction: row;
     }
   }

   &__nav__list {
     display: flex;
     margin-bottom: 40px;
   }

   &__nav__item {
     margin: 0 20px;

     &:first-child {
       margin-left: 0;
     }

     &:last-child {
       margin-right: 0;
     }
   }

   &__nav__link {
     color: $white;
     text-decoration: none;

     transition: .3s ease-in-out;

     &--active {
       border-bottom: 2px solid $white;
     }

     &:hover, &:focus {
       color: $brand;
     }
   }

   &__copyright {
    display: flex;
    flex-direction: column;

    margin-top: 100px;
    text-align: center;
    font-size: 16px;

    img {
      margin-bottom: 50px;

      @include for-tablet-landscape-up {
        margin-bottom: 0;
      }
    }

    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
   }
}