.homepage {

  background-color: #fff;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 60px 0 80px;

    @include for-tablet-landscape-up {
      margin: 0;
      padding: 5vh 0 8vh;
    } 

    &__title {
      display: flex; 
      flex-direction: column;
      align-items: center;
      font-size: 45px;
      text-align: center;
      line-height: 130%;
      color: #000;

      margin-bottom: 25px;

      @include for-tablet-landscape-up {
        margin-bottom: 50px;
        font-size: 3.5vw;
      }

      .title-element {
        opacity: 0;
        transition: .6s ease-in-out;
      }

      .title-element-visible {
        opacity: 1;
        transition: .6s ease-in-out;
      }

      &--line1,
      &--line2 {
        position: relative;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &__image {
        position: relative;

        width: 160px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        filter: grayscale(1);
        transition: .3s ease-in-out forwards;

        opacity: 0;
        animation: fade .3s ease-in-out 1s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -ms-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        &:hover {
          filter: grayscale(0);
        }

        &:nth-child(1) {
          margin-bottom: 40px;
        }

        &:nth-child(2) {
          margin-top: 40px;
        }

        @include for-tablet-landscape-up {
          position: absolute;
          
          &:nth-child(1) {
            left: -220px;
            margin-bottom: 20px;
          }
  
          &:nth-child(2) {
            right: -220px;
            margin-top: 20px;
          }
        }

        @include for-desktop-up {
          width: 180px;
          height: 120px;
        }
      }

      @keyframes fade {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .pagenav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    padding-bottom: 8px;
    border-bottom: 2px solid $black;

    &--left {
      grid-column: 1 / 4;
      display: flex;
    }

    &--right {
      grid-column: 4 / 5;
    }

    &__link {
      color: $black;
      text-decoration: none;

      margin-right: 25px;

      font-size: 10px;

      transition: .3s ease-in-out;

      &:hover, &:focus  {
        color: $brand;
      }

      @include for-tablet-landscape-up {
        margin-right: 75px;
        font-size: 10px;
      }
    }

    &__wrapper {
      padding-bottom: 50px;
    }
  }

  .about {
    &__title {
      display: none;
    }

    &__content {
      font-size: 30px;
      line-height: 120%;
  
      margin-bottom: 75px;
  
      @include for-tablet-landscape-up {
        font-size: 40px;
        max-width: 75%;
      }
  
      strong {
        color: $brand;
      }
    }
  }

  .projects {
    &__images {
      display: flex;
      align-items: start;
      width: 200%;
      left: -100px;

      &__block {
        display: block;
        width: 30vw;
        aspect-ratio: 3 / 2;
        margin-right: 25px;
      }

      &__item {
        width: 100%;
        height: 100%;
        object-fit: cover;

        filter: grayscale(1);
        transition: filter .5s ease-in-out;

        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }

  .clients {
    &__title {
      border-top: 2px solid $black;
      padding-top: 100px;


      @include for-tablet-landscape-up {
        margin-bottom: 100px;
      }
    }

    &__images {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      
      width: 100%;
      max-width: 100%;

      margin: 0 auto 100px;

      @include for-tablet-landscape-up {
        width: 75%;
        max-width: 75%;
      }

      &__item {
        height: auto;
        width: calc(100% / 2 - 80px);

        margin: 40px;

        filter: grayscale(1);

        @include for-tablet-landscape-up {
          width: calc(100% / 4 - 180px);
          margin: 80px 90px;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
