.nav {
  padding-bottom: 0;

  z-index: 9;

  &__visible {
    position: relative;

    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 150px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__menu-button {
    display: flex;
    align-items: center;

    z-index: 15;

    @include for-tablet-landscape-up {
      position: fixed;
      right: 100px;
    }

    button {
      margin: 0;
      color: $brand;
    }

    &__list {
      height: 14px;
      overflow-y: hidden;
      margin-left: 5px;

      transform-origin: 0 0;

      transition: .3s ease-in-out;

      &:hover, &:focus {
        height: calc(3 * 19px);
      }

      &__item {
        margin-bottom: 5px;
      }

      &__link {
        color: $black;
        text-decoration: none;

        &:hover, &:focus {
          color: $brand;
        }
      }
    }
  }

  &__hidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: absolute;

    z-index: 10;

    width: calc(100vw - 40px);
    height: 100vh;

    @include for-tablet-landscape-up {
      width: calc(100vw - 160px);
    }

    @include for-desktop-up {
      width: 100%;
    }

    &:before {
      content: "";

      display: block;

      position: fixed;
      bottom: 65px;
      left: 50%;

      transform: translateX(-50%);

      width: 80%;
      height: 2px;

      background-color: $black;
    }
  }

  &__hidden__list {
    position: fixed;

    margin: 0 auto;

    animation: fade-down .4s ease-out;

    @include for-desktop-up {
      transform: translateX(0);
      text-align: left;
    }

    &__link {
      display: inline-block;
      font-size: 35px;
      color: $black;
      text-decoration: none;
      line-height: 150%;

      transition: .3s ease-in-out;

      @include for-tablet-landscape-up {
        font-size: 7vw;
        line-height: 120%;
      }

      &:hover, &:focus {
        color: $brand;
      }
    }
  }

  @keyframes fade-down {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translateY(0)
    }
  }
}