.clientpage {
  .pageTitle {
    font-size: 35px;
    font-weight: 500;

    margin: 75px 0 0 0;

    @include for-tablet-landscape-up {
      font-size: 50px;
    }
  }

  .content {
    padding-top: 0;

    &__preline {
      color: $brand;
      margin-bottom: 20px;
    }

    &__item {
      line-height: 120%;
      max-width: 550px;

      margin-bottom: 50px;

      display: flex;
      flex-direction: column;
    }

    &__item__link {
      color: $black;

      transition: .3s ease-in-out;

      &:hover, &:focus {
        color: $brand;
      }
    }
  }

  .clients__images {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    
    width: 100%;
    max-width: 100%;

    @include for-tablet-landscape-up {
      width: 90%;
      max-width: 90%;
    }

    &__item {
      height: auto;
      max-height: 150px;
      width: calc(100% - 20px);

      margin: 0 20px 50px 0;

      filter: grayscale(1);

      @include for-tablet-portrait-up {
        width: calc(50% - 50px);
        margin: 0 50px 50px 0;
      }

      @include for-tablet-landscape-up {
        width: calc(25% - 100px);
        margin: 0 100px 100px 0;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}
