/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.wrapper {
  padding: 0 20px;
  position: relative; }
  @media (min-width: 600px) {
    .wrapper {
      padding: 0 10rem;
      max-width: 1300px;
      margin: 0 auto; } }

.me-pagetitle {
  font-size: 9vw;
  font-weight: 500; }

.me-link {
  text-decoration: none;
  color: #EE4135;
  font-size: 22px;
  transition: 0.2s ease-in-out; }
  .me-link:hover, .me-link:focus {
    color: #000000; }

.me-body {
  font-size: 20px;
  line-height: 33px;
  color: #000000;
  font-family: "Poppins", sans-serif; }

.me-h3 {
  font-size: 20px;
  font-weight: 500; }
  @media (min-width: 600px) {
    .me-h3 {
      font-size: 25px;
      margin-bottom: 20px; } }

input,
select,
textarea {
  font-family: "Poppins", sans-serif;
  border: none;
  border: 1px solid #000000;
  margin-top: 8px;
  font-size: 20px;
  padding: 6px;
  width: 100%;
  max-width: 95%;
  background-color: transparent;
  margin-bottom: 30px;
  transition: 0.2s ease-in-out; }
  input:focus, input:hover, input:focus,
  select:focus,
  select:hover,
  select:focus,
  textarea:focus,
  textarea:hover,
  textarea:focus {
    outline: none;
    border-color: #EE4135; }

.me-button {
  display: block;
  background-color: transparent;
  border: 2px solid #000000;
  padding: 12px 20px;
  color: #000000;
  text-decoration: none;
  text-transform: lowercase;
  font-size: 20px;
  width: auto;
  transition: 0.2s ease-in-out;
  display: inline-block;
  color: #ffffff;
  background-color: #000000; }
  .me-button:hover, .me-button:focus {
    cursor: pointer;
    background-color: transparent;
    color: #EE4135;
    border-color: #EE4135; }
  .me-button--inverted, .me-button--secondary {
    color: #000000;
    background-color: #ffffff; }
  .me-button:hover, .me-button:focus {
    color: #ffffff;
    background-color: #EE4135; }
  .me-button--naked {
    color: #000000;
    background-color: transparent;
    border: none;
    padding: 0; }
    .me-button--naked:hover, .me-button--naked:focus {
      color: #EE4135;
      background-color: transparent; }

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0); }

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0); }

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

.me-page-container {
  position: relative;
  width: 100%;
  max-width: 100vw;
  align-items: start;
  display: grid;
  grid-template-columns: 20px repeat(6, 1fr) 20px;
  padding: 20px 0; }
  @media (min-width: 600px) {
    .me-page-container {
      grid-template-columns: 40px repeat(6, 1fr) 40px;
      padding: 40px 0; } }
  @media (min-width: 900px) {
    .me-page-container {
      grid-template-columns: 80px repeat(6, 1fr) 80px;
      padding: 80px 0; } }
  @media (min-width: 1200px) {
    .me-page-container {
      grid-template-columns: 1fr repeat(6, 200px) 1fr;
      row-gap: 10px;
      column-gap: 5px; } }
  @media (min-width: 1600px) {
    .me-page-container {
      grid-template-columns: 1fr repeat(6, 265px) 1fr; } }
  .me-page-container__content1\/-1 {
    grid-column: 1 / -1;
    position: relative; }
  .me-page-container__content1\/7 {
    grid-column: 1 / 7;
    position: relative; }
  .me-page-container__content-center {
    grid-column: 2 / -2;
    position: relative; }
  .me-page-container__content2\/3 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content2\/3 {
        grid-column: 2 / 3; } }
  .me-page-container__content2\/4 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content2\/4 {
        grid-column: 2 / 4; } }
  .me-page-container__content3\/4 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content3\/4 {
        grid-column: 3 / 4; } }
  .me-page-container__content2\/5 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content2\/5 {
        grid-column: 2 / 5; } }
  .me-page-container__content2\/6 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content2\/6 {
        grid-column: 2 / 6; } }
  .me-page-container__content2\/7 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content2\/7 {
        grid-column: 2 / 7; } }
  .me-page-container__content3\/5 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content3\/5 {
        grid-column: 3 / 5; } }
  .me-page-container__content4\/5 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content4\/5 {
        grid-column: 4 / 5; } }
  .me-page-container__content5\/6 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content5\/6 {
        grid-column: 5 / 6; } }
  .me-page-container__content4\/8 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content4\/8 {
        grid-column: 4 / 8; } }
  .me-page-container__content7\/8 {
    grid-column: 2 / -2;
    position: relative; }
    @media (min-width: 1200px) {
      .me-page-container__content7\/8 {
        grid-column: 7 / 8; } }

.hidden {
  display: none !important; }

input,
textarea,
select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type="radio"] {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial; }

.mobile__nav__open {
  overflow: hidden; }

.text-center {
  text-align: center; }

.nav {
  padding-bottom: 0;
  z-index: 9; }
  .nav__visible {
    position: relative;
    display: flex;
    justify-content: space-between; }
  .nav__logo {
    width: 150px; }
    .nav__logo img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .nav__menu-button {
    display: flex;
    align-items: center;
    z-index: 15; }
    @media (min-width: 900px) {
      .nav__menu-button {
        position: fixed;
        right: 100px; } }
    .nav__menu-button button {
      margin: 0;
      color: #EE4135; }
    .nav__menu-button__list {
      height: 14px;
      overflow-y: hidden;
      margin-left: 5px;
      transform-origin: 0 0;
      transition: .3s ease-in-out; }
      .nav__menu-button__list:hover, .nav__menu-button__list:focus {
        height: calc(3 * 19px); }
      .nav__menu-button__list__item {
        margin-bottom: 5px; }
      .nav__menu-button__list__link {
        color: #000000;
        text-decoration: none; }
        .nav__menu-button__list__link:hover, .nav__menu-button__list__link:focus {
          color: #EE4135; }
  .nav__hidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    z-index: 10;
    width: calc(100vw - 40px);
    height: 100vh; }
    @media (min-width: 900px) {
      .nav__hidden {
        width: calc(100vw - 160px); } }
    @media (min-width: 1200px) {
      .nav__hidden {
        width: 100%; } }
    .nav__hidden:before {
      content: "";
      display: block;
      position: fixed;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 2px;
      background-color: #000000; }
  .nav__hidden__list {
    position: fixed;
    margin: 0 auto;
    animation: fade-down .4s ease-out; }
    @media (min-width: 1200px) {
      .nav__hidden__list {
        transform: translateX(0);
        text-align: left; } }
    .nav__hidden__list__link {
      display: inline-block;
      font-size: 35px;
      color: #000000;
      text-decoration: none;
      line-height: 150%;
      transition: .3s ease-in-out; }
      @media (min-width: 900px) {
        .nav__hidden__list__link {
          font-size: 7vw;
          line-height: 120%; } }
      .nav__hidden__list__link:hover, .nav__hidden__list__link:focus {
        color: #EE4135; }

@keyframes fade-down {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    transform: translateY(0); } }

.footer {
  background-color: #000000;
  color: #ffffff;
  z-index: 1; }
  .footer__intro {
    margin-top: 75px;
    margin-bottom: 100px;
    font-size: 30px;
    line-height: 120%; }
    @media (min-width: 900px) {
      .footer__intro {
        max-width: 60%;
        font-size: 40px;
        margin-bottom: 150px; } }
    .footer__intro strong {
      color: #EE4135; }
  .footer .contact__small {
    margin-bottom: 20px;
    color: #EE4135; }
  .footer .contact__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    line-height: 150%; }
    .footer .contact__info__link {
      text-decoration: none;
      color: #ffffff;
      transition: .3s ease-in-out; }
      .footer .contact__info__link:hover, .footer .contact__info__link:focus {
        color: #EE4135; }
  @media (min-width: 900px) {
    .footer__button {
      justify-self: end; } }
  .footer__nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 150px;
    padding-top: 50px;
    border-top: 2px solid #ffffff; }
    @media (min-width: 900px) {
      .footer__nav {
        flex-direction: row; } }
  .footer__nav__list {
    display: flex;
    margin-bottom: 40px; }
  .footer__nav__item {
    margin: 0 20px; }
    .footer__nav__item:first-child {
      margin-left: 0; }
    .footer__nav__item:last-child {
      margin-right: 0; }
  .footer__nav__link {
    color: #ffffff;
    text-decoration: none;
    transition: .3s ease-in-out; }
    .footer__nav__link--active {
      border-bottom: 2px solid #ffffff; }
    .footer__nav__link:hover, .footer__nav__link:focus {
      color: #EE4135; }
  .footer__copyright {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    text-align: center;
    font-size: 16px; }
    .footer__copyright img {
      margin-bottom: 50px; }
      @media (min-width: 900px) {
        .footer__copyright img {
          margin-bottom: 0; } }
    @media (min-width: 900px) {
      .footer__copyright {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: left; } }

.homepage {
  background-color: #fff; }
  .homepage__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0 80px; }
    @media (min-width: 900px) {
      .homepage__header {
        margin: 0;
        padding: 5vh 0 8vh; } }
    .homepage__header__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 45px;
      text-align: center;
      line-height: 130%;
      color: #000;
      margin-bottom: 25px; }
      @media (min-width: 900px) {
        .homepage__header__title {
          margin-bottom: 50px;
          font-size: 3.5vw; } }
      .homepage__header__title .title-element {
        opacity: 0;
        transition: .6s ease-in-out; }
      .homepage__header__title .title-element-visible {
        opacity: 1;
        transition: .6s ease-in-out; }
      .homepage__header__title--line1, .homepage__header__title--line2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
      .homepage__header__title__image {
        position: relative;
        width: 160px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        filter: grayscale(1);
        transition: .3s ease-in-out forwards;
        opacity: 0;
        animation: fade .3s ease-in-out 1s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -ms-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards; }
        .homepage__header__title__image:hover {
          filter: grayscale(0); }
        .homepage__header__title__image:nth-child(1) {
          margin-bottom: 40px; }
        .homepage__header__title__image:nth-child(2) {
          margin-top: 40px; }
        @media (min-width: 900px) {
          .homepage__header__title__image {
            position: absolute; }
            .homepage__header__title__image:nth-child(1) {
              left: -220px;
              margin-bottom: 20px; }
            .homepage__header__title__image:nth-child(2) {
              right: -220px;
              margin-top: 20px; } }
        @media (min-width: 1200px) {
          .homepage__header__title__image {
            width: 180px;
            height: 120px; } }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
      .homepage__header__title div {
        display: flex;
        align-items: center; }
  .homepage .pagenav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    padding-bottom: 8px;
    border-bottom: 2px solid #000000; }
    .homepage .pagenav--left {
      grid-column: 1 / 4;
      display: flex; }
    .homepage .pagenav--right {
      grid-column: 4 / 5; }
    .homepage .pagenav__link {
      color: #000000;
      text-decoration: none;
      margin-right: 25px;
      font-size: 10px;
      transition: .3s ease-in-out; }
      .homepage .pagenav__link:hover, .homepage .pagenav__link:focus {
        color: #EE4135; }
      @media (min-width: 900px) {
        .homepage .pagenav__link {
          margin-right: 75px;
          font-size: 10px; } }
    .homepage .pagenav__wrapper {
      padding-bottom: 50px; }
  .homepage .about__title {
    display: none; }
  .homepage .about__content {
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 75px; }
    @media (min-width: 900px) {
      .homepage .about__content {
        font-size: 40px;
        max-width: 75%; } }
    .homepage .about__content strong {
      color: #EE4135; }
  .homepage .projects__images {
    display: flex;
    align-items: start;
    width: 200%;
    left: -100px; }
    .homepage .projects__images__block {
      display: block;
      width: 30vw;
      aspect-ratio: 3 / 2;
      margin-right: 25px; }
    .homepage .projects__images__item {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(1);
      transition: filter .5s ease-in-out; }
      .homepage .projects__images__item:hover {
        filter: grayscale(0); }
  .homepage .clients__title {
    border-top: 2px solid #000000;
    padding-top: 100px; }
    @media (min-width: 900px) {
      .homepage .clients__title {
        margin-bottom: 100px; } }
  .homepage .clients__images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    margin: 0 auto 100px; }
    @media (min-width: 900px) {
      .homepage .clients__images {
        width: 75%;
        max-width: 75%; } }
    .homepage .clients__images__item {
      height: auto;
      width: calc(100% / 2 - 80px);
      margin: 40px;
      filter: grayscale(1); }
      @media (min-width: 900px) {
        .homepage .clients__images__item {
          width: calc(100% / 4 - 180px);
          margin: 80px 90px; } }
      .homepage .clients__images__item img {
        object-fit: contain;
        width: 100%;
        height: 100%; }

.workpage {
  height: auto; }
  .workpage .pageTitle {
    font-size: 35px;
    font-weight: 500;
    margin: 75px 0 35px 0; }
    @media (min-width: 900px) {
      .workpage .pageTitle {
        font-size: 50px;
        margin: 0; } }
  .workpage .filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 2px solid #000000; }
    @media (min-width: 900px) {
      .workpage .filters {
        flex-direction: row; } }
    .workpage .filters__wrapper {
      padding: 0; }
    .workpage .filters__right {
      display: flex;
      flex-direction: column; }
      @media (min-width: 900px) {
        .workpage .filters__right {
          flex-direction: row; } }
    .workpage .filters__link {
      display: inline-block;
      margin: 10px 0;
      text-decoration: none;
      color: #000000;
      transition: .3s ease-in-out; }
      @media (min-width: 900px) {
        .workpage .filters__link {
          margin: 0 20px; } }
      .workpage .filters__link:hover, .workpage .filters__link:focus {
        color: #EE4135; }
      .workpage .filters__link:first-child {
        margin-left: 0; }
      .workpage .filters__link:last-child {
        margin-right: 0; }
  .workpage .work {
    height: auto; }
    .workpage .work__active {
      margin-bottom: 75px; }
      @media (min-width: 900px) {
        .workpage .work__active {
          position: sticky;
          top: 20px; } }
      .workpage .work__active__image {
        width: 100%; }
        @media (min-width: 900px) {
          .workpage .work__active__image {
            width: calc(100% - 40px); } }
        .workpage .work__active__image img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .workpage .work__active__title {
        margin-top: 25px;
        margin-bottom: 25px;
        font-weight: 400; }
      .workpage .work__active__labels {
        color: #EE4135;
        margin-bottom: 20px; }
      .workpage .work__active__content {
        max-width: 100%;
        line-height: 120%; }
        @media (min-width: 900px) {
          .workpage .work__active__content {
            max-width: 85%; } }
    .workpage .work__list {
      min-height: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%; }
      .workpage .work__list__item {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 55vw;
        margin-bottom: 40px;
        border: none;
        filter: grayscale(1);
        transition: .3s ease-in-out; }
        @media (min-width: 900px) {
          .workpage .work__list__item {
            width: calc(50% - 20px);
            height: 350px;
            margin-bottom: 40px; } }
        .workpage .work__list__item:hover {
          cursor: pointer;
          filter: grayscale(0); }
        .workpage .work__list__item img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover; }

.contactpage .pageTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 75px 0 50px; }
  @media (min-width: 900px) {
    .contactpage .pageTitle {
      font-size: 50px; } }

.contactpage .content {
  padding-top: 0; }
  .contactpage .content__preline {
    color: #EE4135;
    margin-bottom: 20px; }
  .contactpage .content__item {
    line-height: 120%;
    max-width: 450px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column; }
  .contactpage .content__item__link {
    color: #000000;
    transition: .3s ease-in-out; }
    .contactpage .content__item__link:hover, .contactpage .content__item__link:focus {
      color: #EE4135; }

.clientpage .pageTitle {
  font-size: 35px;
  font-weight: 500;
  margin: 75px 0 0 0; }
  @media (min-width: 900px) {
    .clientpage .pageTitle {
      font-size: 50px; } }

.clientpage .content {
  padding-top: 0; }
  .clientpage .content__preline {
    color: #EE4135;
    margin-bottom: 20px; }
  .clientpage .content__item {
    line-height: 120%;
    max-width: 550px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column; }
  .clientpage .content__item__link {
    color: #000000;
    transition: .3s ease-in-out; }
    .clientpage .content__item__link:hover, .clientpage .content__item__link:focus {
      color: #EE4135; }

.clientpage .clients__images {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%; }
  @media (min-width: 900px) {
    .clientpage .clients__images {
      width: 90%;
      max-width: 90%; } }
  .clientpage .clients__images__item {
    height: auto;
    max-height: 150px;
    width: calc(100% - 20px);
    margin: 0 20px 50px 0;
    filter: grayscale(1); }
    @media (min-width: 600px) {
      .clientpage .clients__images__item {
        width: calc(50% - 50px);
        margin: 0 50px 50px 0; } }
    @media (min-width: 900px) {
      .clientpage .clients__images__item {
        width: calc(25% - 100px);
        margin: 0 100px 100px 0; } }
    .clientpage .clients__images__item img {
      object-fit: contain;
      width: 100%;
      height: 100%; }

html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth; }

body {
  width: 100vw;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  overflow-x: hidden;
  animation: fadein 0.5s linear; }

::selection {
  color: #fcf9ea;
  background: #EE4135; }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

main {
  position: relative; }

main, footer {
  transition: .3s ease-in-out; }

.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
  color: #fff; }

.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color: #000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  border-radius: 15px;
  border: 4px solid yellow;
  text-align: center;
  font-size: 1.2em;
  z-index: 999; }

.nav-active {
  opacity: .5;
  filter: blur(50px);
  background-color: #aaa;
  animation: blur .5s;
  transition: .3s ease-in-out; }

footer.nav-active {
  animation: invertblur 1s ease-in-out; }

@keyframes blur {
  0% {
    filter: blur(0); }
  100% {
    filter: blur(50px); } }

h2 {
  font-size: 30px;
  font-weight: 500; }
  @media (min-width: 900px) {
    h2 {
      font-size: 45px;
      font-weight: 500; } }

.pagecontent {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s ease-in-out; }

.pagecontent.hidebody {
  opacity: 0;
  transform: translateY(150px); }

@keyframes fadedown {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(150px);
    display: none; } }

.title__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px; }
  .title__header .me-button {
    margin-top: 25px; }
  @media (min-width: 900px) {
    .title__header {
      flex-direction: row; } }

.small-copy {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px; }
