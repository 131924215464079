.contactpage {
  .pageTitle {
    font-size: 35px;
    font-weight: 500;

    margin: 75px 0 50px;

    @include for-tablet-landscape-up {
      font-size: 50px;
    }
  }

  .content {
    padding-top: 0;

    &__preline {
      color: $brand;
      margin-bottom: 20px;
    }

    &__item {
      line-height: 120%;
      max-width: 450px;

      margin-bottom: 50px;

      display: flex;
      flex-direction: column;
    }

    &__item__link {
      color: $black;

      transition: .3s ease-in-out;

      &:hover, &:focus {
        color: $brand;
      }
    }
  }
}