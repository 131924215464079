.workpage {
  height: auto;

  .pageTitle {
    font-size: 35px;
    font-weight: 500;
    margin: 75px 0 35px 0;

    @include for-tablet-landscape-up {
      font-size: 50px;
      margin: 0;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    padding-bottom: 8px;
    border-bottom: 2px solid $black;

    @include for-tablet-landscape-up {
      flex-direction: row;
    }

    &__wrapper {
      padding: 0;
    }

    &__right {
      display: flex;
      flex-direction: column;

      @include for-tablet-landscape-up {
        flex-direction: row;
      }
    }

    &__link {
      display: inline-block;
      margin: 10px 0;
      text-decoration: none;
      color: $black;

      transition: .3s ease-in-out;

      @include for-tablet-landscape-up {
        margin: 0 20px;
      }

      &:hover, &:focus {
        color: $brand;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .work {
    height: auto;
    &__active {
      margin-bottom: 75px;

      @include for-tablet-landscape-up {
        position: sticky;
        top: 20px;
      }

      &__image {
        width: 100%;

        @include for-tablet-landscape-up {
          width: calc(100% - 40px);
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__title {
        margin-top: 25px;
        margin-bottom: 25px;
        font-weight: 400;
      }

      &__labels {
        color: $brand;
        margin-bottom: 20px;
      }

      &__content {
        max-width: 100%;
        line-height: 120%;

        @include for-tablet-landscape-up {
          max-width: 85%;
        }
      }
    }

    &__list {
      min-height: 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      max-width: 100%;

      &__item {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 55vw;
        margin-bottom: 40px;

        border: none;

        filter: grayscale(1);
        transition: .3s ease-in-out;

        @include for-tablet-landscape-up {
          width: calc(50% - 20px);
          height: 350px;
          margin-bottom: 40px;
        }

        &:hover {
          cursor: pointer;
          filter: grayscale(0);
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;

          transform: translate(-50%, -50%);

          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}