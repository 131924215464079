.hidden {
  display: none !important;
}

input,
textarea,
select {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="radio"] {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
}

.mobile__nav__open {
  overflow: hidden;
}

.text-center {
  text-align: center;
}