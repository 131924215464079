.me-page-container {
  position: relative;
  width: 100%;
  max-width: 100vw;

  align-items: start;

  display: grid;
  grid-template-columns: 20px repeat(6, 1fr) 20px;

  padding: 20px 0;

  @include for-tablet-portrait-up {
    grid-template-columns: 40px repeat(6, 1fr) 40px;

    padding: 40px 0;
  }

  @include for-tablet-landscape-up {
    grid-template-columns: 80px repeat(6, 1fr) 80px;

    padding: 80px 0;
  }

  @include for-desktop-up {
    grid-template-columns: 1fr repeat(6, 200px) 1fr;
    row-gap: 10px;
    column-gap: 5px;
  }

  @include for-desktop-large {
    grid-template-columns: 1fr repeat(6, 265px) 1fr;
  }

  &__content1\/-1 {
    grid-column: 1 / -1;
    position: relative;
  }

  &__content1\/7 {
    grid-column: 1 / 7;
    position: relative;
  }

  &__content-center {
    grid-column: 2 / -2;
    position: relative;
  }

  &__content2\/3 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 2 / 3;
    }
  }

  &__content2\/4 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 2 / 4;
    }
  }

  &__content3\/4 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 3 / 4;
    }
  }

  &__content2\/5 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 2 / 5;
    }
  }

  &__content2\/6 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 2 / 6;
    }
  }

  &__content2\/7 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 2 / 7;
    }
  }

  &__content3\/5 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 3 / 5;
    }
  }

  &__content4\/5 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 4 / 5;
    }
  }

  &__content5\/6 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 5 / 6;
    }
  }

  &__content4\/8 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 4 / 8;
    }
  }

  &__content7\/8 {
    grid-column: 2 / -2;
    position: relative;

    @include for-desktop-up {
      grid-column: 7 / 8;
    }
  }
}
