// general imports
@import './general/reset';
@import './general/vars';

// mixins imports
@import './mixins/screens';
@import './mixins/uiElements';

// ui imports
@import './ui/aos';
@import './ui/containers';
@import './ui/default';

// component imports
@import './components/nav';
@import './components/footer';

// page imports
@import './pages/home';
@import './pages/work';
@import './pages/contact';
@import './pages/clients';

// general css
html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 300;
  overflow-x: hidden;

  animation: fadein 0.5s linear;
}

::selection {
  color: $bg-light;
  background: $brand;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

main {
  position: relative;
}

main, footer {
  transition: .3s ease-in-out;
}

.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
  color: #fff;
}

.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color:#000;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow:auto;
  margin: 10px 35%;
  padding:5px;
  border-radius: 15px;
  border:4px solid yellow;
  text-align:center;
  font-size:1.2em;
  z-index:999;
}

.nav-active {
  opacity: .5;
  filter: blur(50px);
  background-color: #aaa;

  animation: blur .5s;
  transition: .3s ease-in-out;
}

footer.nav-active {
  animation: invertblur 1s ease-in-out;
}

@keyframes blur {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(50px);
  }
}

h2 {
  font-size: 30px;
  font-weight: 500;

  @include for-tablet-landscape-up {
    font-size: 45px;
    font-weight: 500;
  }
}

.pagecontent {
  opacity: 1;
  transform: translateY(0);

  transition: 0.3s ease-in-out;
}

.pagecontent.hidebody {
  opacity: 0;
  transform: translateY(150px);
}

@keyframes fadedown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(150px);
    display: none;
  }
}

.title__header {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  .me-button {
    margin-top: 25px;
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.small-copy {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
